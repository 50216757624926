<template>
  <div class="search_out">
    <div class="search_inline">
      <div class="search_box">
        <i class="icon-joyo search_icon">&#xe71e;</i>
        <form
          class="search_input"
          action="javascript:void 0"
        >
          <input
            ref="inputEnter"
            v-model="value"
            class="autofocus"
            :placeholder="placeholder"
            maxlength="50"
            :autofocus="!needBtn"
            @keyup.13="search"
            @input="clear"
          >
        </form>
        <i
          v-if="value"
          class="icon-joyo search_icon showhidden"
          @click="clearValue"
        >&#xe6ec;</i>
      </div>
      <div
        class="search_btn"
        @click="cancel"
      >
        取消
      </div>
    </div>
    <div class="search_list_out">
      <div :class="needBtn? 'search_list_btn' : 'search_list'">
        <ul
          class="search-ul"
          :class="needBtn ? 'search_url_btn' : 'search-ul'"
          :style="{border: searchType === 'oppoCode' ? '0px' : ' '}"
        >
          <li
            v-for="(item, index) of listData"
            :key="index"
            :class="searchType === 'oppoCode' ? 'search-li-oppoCode-pad' : 'search-li'"
            @click="choose(item, index)"
          >
            <!-- 人员树 -->
            <div
              v-if="searchType === 'personnelTree'"
              class="search-li-name"
            >
              <div class="search-imgbox">
                <img
                  src="@/assets/img/person.png"
                  alt=""
                >
              </div>
              <div class="search-personinfo">
                <span class="search-staff">{{ item.realName }}</span>
                <span class="search-depart">{{ item.orgTitle ? item.orgTitle : '' }}</span>
              </div>
            </div>
            <!-- 部门树 -->
            <div
              v-else-if="searchType === 'departTree'"
              class="search-li-name"
            >
              <span class="search-departName">{{ item.key }}</span>
            </div>
            <div
              v-else-if="searchType === 'custContacts'"
              class="search-li-name"
            >
              <span class="search-departName">{{ item.title }}</span>
            </div>
            <div
              v-else-if="searchType === 'oppoCode'"
              class="search-li-selectOppo"
            >
              <div class="search-li-detail">
                <div class="search-li-genre">
                  <div class="search-li-class">
                    {{ item.oppoLevel }}
                  </div>
                  <div class="search-li-combo">
                    {{ item.busTypeCn }}-{{ item.comboName }}
                  </div>
                </div>
                <div class="search-li-oppoCode">
                  {{ item.oppoCode }}
                </div>
                <div
                  v-if="item.reliable"
                  class="search-li-trackReliable"
                >
                  <span class="icon-joyo arrows">{{ reliableMatch(item.reliable) }}</span>
                  <span>{{ item.trackReliableName }}</span>
                </div>
                <div
                  v-if="item.shareStatusFlag"
                  class="search-li-isShare"
                >
                  分享给我的
                </div>
              </div>
              <div
                class="search-li-status"
                :style="matchColors(item.oppoStatusName)"
              >
                {{ item.oppoStatusName }}
              </div>
            </div>
            <div
              v-else
              class="search-li-name"
            >
              <span class="search-departName">{{ `${item.title}${item.comboName ? item.comboName : ''}` }}</span>
            </div>
            <i
              v-if="searchType !== 'oppoCode' && initialIndex !=='' ? initialIndex === index : false"
              class="icon-joyo search-li-icon"
            >&#xe728;</i>
          </li>
          <error-page
            v-if="!listData.length"
            :class="needBtn ? 'errorPages' : 'errorPage'"
          />
        </ul>
      </div>
    </div>
    <div
      v-if="searchType === 'custContacts' && needBtn"
      class="bottom-postion btn-return-other"
    >
      <cube-button
        :light="true"
        class="btn-return"
        @click="cancel"
      >
        返回
      </cube-button>
      <cube-button
        type="submit"
        class="btn btns"
        @click="toContact"
      >
        新增联系人
      </cube-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import errorPage from '_c/errorPage';
import { debounce, findValueByKey, generReg, matchColor, regular, reliableMatch } from '_lib/until';
import { phoneReg } from '_lib/validate';

import optionTree from '@/mixins/optionTree';

export default {
  components: {
    errorPage
  },
  mixins: [optionTree],
  props: {
    data: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入搜索内容'
    },
    func: {
      type: Function,
      default: () => {
        return {};
      }
    },
    defaultFunc: {
      type: Function,
      default: () => {
        return {};
      }
    },
    searchType: {
      type: String,
      default: ''
    },
    defineList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    defaultRequestData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    needBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: '',
      realName: '',
      listData: [],
      initialIndex: ''
    };
  },
  computed: {
    ...mapGetters(['dictList'])
  },
  watch: {
    // value(val) {
    //   if (val && this.searchType === 'defineSearch') {
    //     clearTimeout(this.timer);
    //     this.result = [];
    //     this.timer = setTimeout(() => {
    //       let keys = Object.keys(this.defineList);
    //       Object.keys(this.defineList).filter(x => {
    //         let bool = this.generRegs(val).test(x);
    //         let boolch = this.generRegs(val).test(this.defineList[x]);
    //         if (bool || boolch) {
    //           this.result.push({a: x, b: this.defineList[x]});
    //         }
    //         return bool || boolch;
    //       });
    //     }, 200);
    //   }
    // }
  },
  mounted() {
    if (this.data) {
      this.value = this.data || '';
      // this.getList();
    }
    this.getDefaultData();
    this.getIndex();
  },
  activated() {
    this.getDefaultData();
  },
  methods: {
    search() {
      if (this.searchType === 'custCnCreditcode') {
        const rex = /^[A-Za-z0-9]+$/;
        if (rex.test(this.value)) {
          const reg = /^[A-Za-z0-9]{18}/;
          if (!reg.test(this.value)) {
            this.$showToast('不得少于18位');
            return;
          }
        } else {
          if (this.value !== '' && this.value.length < 4) {
            this.$showToast('不得少于四个字');
            return false;
          }
        }
      } else if ((this.searchType === 'custCn' || this.searchType === 'custName') && this.value !== '' && this.value.length < 4) {
        this.$showToast('不得少于四个字');
        return;
      }
      const debounceList = debounce(this.getList, 500);
      debounceList();
    },
    // 对焦
    focusd() {
      this.$refs.inputEnter.focus();
    },
    cancel() {
      this.$emit('conceal', false);
    },
    choose(data, index) {
      this.value = '';
      this.initialIndex = index;
      const { typeName } = this.route.params;
      let orgsales = {};
      switch (this.searchType) {
        case 'personnelTree':
          orgsales = {
            [typeName]: {
              name: data.realName,
              value: data.id,
              orgId: data.orgId
            }
          };
          this.storageVuex(orgsales);
          break;
        case 'departTree':
          orgsales = {
            [typeName]: {
              name: data.orgTitle,
              value: data.id
            }
          };
          this.storageVuex(orgsales);
          break;
        default:
          this.$emit('option', data);
      };
    },
    toTransfer(orgsales) {
      const newFun = 'toTransfer';
      this.$emit(newFun, orgsales);
    },
    clear() {
      // this.value = '';
      this.$emit('clear', this.value);
    },
    clearValue() {
      this.value = '';
      this.$emit('clear', this.value);
    },
    getList() {
      this.listData = [];
      switch (this.searchType) {
        case 'custCn':
          this.func(this.value).then(res => {
            if (res.flag) {
              res.data.custInfoCnDtoList.forEach(item => {
                this.listData.push(
                  {
                    title: item.custCn,
                    id: item.id,
                    custStatus: item.custStatus
                  }
                );
              });
            } else {
              this.$showToast(res.errorMsg);
            }
          });
          break;
        case 'oppoCode':
          this.func({ ...this.defaultRequestData, searchTitle: this.value, needShareOppoFlag: true }).then(res => {
            if (res.flag) {
              res.data.busOppoCodeList.forEach(item => {
                this.listData.push(
                  {
                    oppoLevel: findValueByKey(this.dictList.oppoLevel, item.oppoLevel) + '级',
                    busTypeId: item.busTypeId,
                    busTypeCn: findValueByKey(this.dictList.bustype, item.busTypeId),
                    comboName: item.comboName,
                    oppoCode: item.oppoCode,
                    trackReliableName: findValueByKey(this.dictList.reliable, item.trackReliable),
                    reliable: item.trackReliable,
                    oppoStatusName: findValueByKey(this.dictList.oppoStatus, item.oppoStatus),
                    shareStatusFlag: item.shareStatusFlag,
                    title: item.oppoCode + item.comboName,
                    id: item.id
                  }
                );
              });
            } else {
              this.$showToast(res.errorMsg);
            }
          });
          break;
        case 'personnelTree':
          this.func({ realName: this.value, needOrg: true }).then((res) => {
            if (res.flag) {
              res.data.forEach(item => {
                if (item.orgResDtoList !== null) {
                  item.orgResDtoList.forEach(i => {
                    this.listData.push(
                      {
                        realName: item.realName,
                        id: item.id,
                        orgId: i.id,
                        orgTitle: regular(i.key)
                      }
                    );
                  });
                }
              });
            }
          });
          break;
        case 'departTree':
          this.func(this.value, 1).then((res) => {
            if (res.flag) {
              this.listData = res.data;
            }
          });
          break;
        case 'custContacts':
          this.func({ ...this.defaultRequestData, name: this.value }).then(res => {
            if (res.flag) {
              this.listData = res.data.pageInfo.list;
              this.listData.forEach(item => {
                item.title = item.name + '-' + item.position + '-' + item.mobile;
              });
            } else {
              this.$showToast(res.errorMsg);
            }
          });
          break;
        case 'clue':
          this.func({ ...this.defaultRequestData, clueName: !phoneReg(this.value) ? this.value : '', contactsMobile: phoneReg(this.value) ? this.value : '' }).then(res => {
            if (res.flag) {
              this.listData = res.data.pageInfo.list;
              this.listData.forEach(item => {
                item.title = item.clueName;
              });
            } else {
              this.$showToast(res.errorMsg);
            }
          });
          break;
        case 'custCnCreditcode':
          this.func(this.value).then(res => {
            if (res.flag) {
              res.data.custInfoCnDtoList.forEach(item => {
                this.listData.push(
                  {
                    title: item.custCn,
                    id: item.id,
                    custStatus: item.custStatus,
                    custInd: item.custInd
                  }
                );
              });
            } else {
              this.$showToast(res.errorMsg);
            }
          });
          break;
        default: // 不需要联想词
          this.$emit('getLists', this.value, 'inquire');
      }
    },
    getDefaultData() {
      switch (this.searchType) {
        case 'custCn':
          this.defaultFunc && this.defaultFunc().then(res => {
            if (res.flag) {
              res.data.myCustWithBusOppoList.forEach(item => {
                this.listData.push(
                  {
                    title: item.custCn,
                    id: item.custId,
                    custStatus: item.custStatus
                  }
                );
              });
            } else {
              this.$showToast(res.errorMsg);
            }
          });
          break;
        case 'oppoCode':
          this.func(this.defaultRequestData).then(res => {
            if (res.flag) {
              res.data.busOppoCodeList.forEach(item => {
                this.listData.push(
                  {
                    oppoLevel: findValueByKey(this.dictList.oppoLevel, item.oppoLevel) + '级',
                    busTypeId: item.busTypeId,
                    busTypeCn: findValueByKey(this.dictList.bustype, item.busTypeId),
                    comboName: item.comboName,
                    oppoCode: item.oppoCode,
                    trackReliableName: findValueByKey(this.dictList.reliable, item.trackReliable),
                    reliable: item.trackReliable,
                    oppoStatusName: findValueByKey(this.dictList.oppoStatus, item.oppoStatus),
                    shareStatusFlag: item.shareStatusFlag,
                    title: item.oppoCode + item.comboName,
                    id: item.id
                  }
                );
              });
            } else {
              this.$showToast(res.errorMsg);
            }
          });
          break;
        case 'custContacts':
          this.defaultFunc(this.defaultRequestData).then(res => {
            if (res.flag) {
              this.listData = res.data.pageInfo.list;
              this.listData.forEach(item => {
                item.title = `${item.name}${item.position ? '-' + item.position : ''}${item.mobile ? '-' + item.mobile : ''}`;
              });
            } else {
              this.$showToast(res.errorMsg);
            }
          });
          break;
        case 'clue':
          this.defaultFunc(this.defaultRequestData).then(res => {
            if (res.flag) {
              this.listData = res.data.pageInfo.list;
              this.listData.forEach(item => {
                item.title = item.clueName;
              });
            } else {
              this.$showToast(res.errorMsg);
            }
          });
          break;
        case 'custCnCreditcode':
          this.defaultFunc && this.defaultFunc().then(res => {
            if (res.flag) {
              res.data.myCustWithBusOppoList.forEach(item => {
                this.listData.push(
                  {
                    title: item.custCn,
                    id: item.custId,
                    custStatus: item.custStatus,
                    custInd: item.custInd
                  }
                );
              });
            } else {
              this.$showToast(res.errorMsg);
            }
          });
          break;
      };
      if (this.listData.length > 10) this.listData.splice(0, 10);
    },
    getIndex() {
      for (let i = 0; i < this.listData.length; i++) {
        const item = this.listData[i];
        if (this.value === item) {
          this.initialIndex = i;
        }
      }
    },
    generRegs() {
      return generReg;
    },
    reliableMatch(val) {
      return reliableMatch(val);
    },
    matchColors(val) {
      return matchColor(val);
    },
    toContact() {
      // const { custId, custCn } = this.route.query;
      const custCn = this.route.query.custCn || this.defaultRequestData.custCn;
      const custId = this.route.query.custId || this.defaultRequestData.custId;
      this.defaultRequestData.custId;
      this.$router.push(
        {
          name: 'addedit-contact',
          query: {
            custCn: custCn,
            custId: custId,
            from: 'qyWeChat'
          }
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped>
.search_out {
  position: fixed;
  top:0;
  left:0;
  z-index: 99;
  width:100%;
  height: 100%;
  overflow: hidden;
  background: $color-FFF;
  .search_inline{
    display: flex;
    height: 36px;
    padding: 14.5px 16px 14.5px 14.5px;
    .search_btn{
      width:45px;
      color: $color-font;
      font-size: 15px;
      line-height: 36px;
      text-align: right;
    }
    .search_box{
      display: flex;
      flex: 1;
      align-items:center;
      justify-content:flex-start;
      height: 33px;
      background-color: $color-F4;
      border: 1px solid $color-E6;
      border-radius:20px ;
      input {
        width: 100%;
        // padding: 10px 0;
        margin-top: 3px;
        line-height: 1;
        background-color: rgba(0, 0, 0, 0);
        border: 0;  // 去除未选中状态边框
        outline: none; // 去除选中状态边框// 透明背景
      }
      .search_icon{
        width:33.5px;
        height: 25px;
        color:$color-CCC;
        font-size:$font-14;
        line-height: 25px;
        text-align: center;
      }
      .search_input{
        flex: 1;
        font-size: 12px;
      }
      .showhidden{
        // color: $color-E6;
        font-size: $font-16;
      }
    }
  }
  .search_list_out{
    z-index: 999;
    width:100%;
    height:calc(100vh - 65px);
    background-color:$color-FFF;
  }
  .search_list_btn{
    z-index: 999;
    width:100%;
    height:calc(100vh - 130px);
    overflow: scroll;
    background-color:$color-F7;
  }
  .search_list{
    z-index: 999;
    width:100%;
    height:calc(100vh - 65px);
    overflow: scroll;
    background-color:$color-F7;
  }
  .search-ul{
    background-color: $color-FFF;
    border-bottom:1px solid $color-E5;
    .search-li-oppoCode-pad{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 15px;
      padding: 16px 0 16px 0;
      border-bottom: 1px solid #EAEBED;
      .search-li-selectOppo{
        display: flex;
        justify-content:space-between;
        width: 100%;
        color: $color-333;
        font-size: $font-15;
        .search-li-detail{
          display: flex;
          flex-direction: column;
          .search-li-genre {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 8px;
            .search-li-class{
              padding: 2px 8px;
              color: #FFF;
              font-size: 12px;
              line-height: 16px;
              background-color: #00A6FF;
              border-radius: 2px;
            }
            .search-li-combo{
              margin-left: 8px;
              color: #23252E;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
            }
          }
          .search-li-oppoCode{
            color: #999BA3;
            font-size: 12px;
            line-height: 16px;
            text-align: left;
          }
          .search-li-trackReliable{
            margin-top: 8px;
            color: #5A5C66;
            font-size: 12px;
            line-height: 16px;
            text-align: left;
          }
          .search-li-isShare{
            width: 60px;
            height: 18px;
            margin-top: 10px;
            padding: 2px 4px;
            color: #5A5C66;
            font-size: 10px;
            line-height: 14px;
            background-color: #F5F5F7;
            border-radius: 2px;
          }
        }
        .search-li-status{
          align-self: center;
          margin-right: 15px;
          padding: 3px 8px;
          color:$color-CCC;
          font-size: 12px;
          line-height: 16px;
          background-color: #F5F5F7;
          border-radius: 11px;
        }
      }
    }
    .search-li-oppoCode-pad:last-child{
      border: 0;
    }
    .search-li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      border-top:1px solid $color-E5;
      .search-li-name {
        display: flex;
        justify-content: flex-start;
        margin-left: 15px;
        color: $color-333;
        font-size: $font-15;
        .search-imgbox {
          width: 40px;
          height: 40px;
          margin-right: 14.5px;
          background-color: #fff;
          border-radius: 50%;
          img {
            width: 40px;
            border-radius: 50%;
          }
        }
        .search-personinfo{
          display: flex;
          flex-direction: column;
          margin-top: 4px;
          text-align: left;
          .search-staff {
            margin-bottom:10px;
            color:$color-333;
            font-size: $font-15;
          }
          .search-depart {
            color:$color-CCC;
            font-size: $font-12;
          }
        }
        .search-departName{
          padding: 10px;
        }
      }
      .search-li-icon {
        margin-right: 14px;
        color: $color-font;
        font-size: $font-14;
      }
    }
  }
  .errorPage{
    height:calc(100vh - 66px);
  }
  .errorPages{
    height:calc(100vh - 130px);
  }
  .arrows{
    margin-right: 4px;
    color: #BFE8FF;
  }
}
</style>
